import Footer from 'components/layout/Footer'
import PreviewNotice from 'components/layout/PreviewNotice'
import HeaderContainer from 'components/renderers/HeaderContainer'
import PageSeo from 'components/seo/PageSeo'
import Env from 'Env'
import { TopPosterModel } from 'models/body/TopPosterModel'
import { AppInfoModel } from 'models/config/AppInfoModel'
import { MenuModel } from 'models/menu/MenuModel'
import { PageModel } from 'models/page/PageModel'
import { GetStaticProps, NextPage } from 'next'
import Head from 'next/head'
import React, { Fragment, FunctionComponent, ReactElement } from 'react'
import { ApiPromise } from 'services/ApiPromise'
import { Converters } from 'services/Converters'
import loadash from 'lodash'
import CarouselSlider from 'components/home-page/carousel-slider'
import AppStoreSection from 'components/home-page/appstore-section'
import HomeHeroSection from 'components/home-page/home-hero'
import Faq from 'components/home-page/faq'
import IndividualSection from 'components/home-page/individual-section'
import MediumOrchidCta from 'components/home-page/medium-orchid-cta'
import ServiceSection from 'components/home-page/services-section'
import TextWithImage from 'components/home-page/text-with-image'
import { HomeHeroModel } from 'models/hero/HomeHeroModel'
import { FaqCollectionModel } from 'models/body/FaqCollectionModel'
import InfoBlocks from 'components/home-page/info-blocks'
type Props = {
  page: PageModel
  menu: MenuModel
  footer: MenuModel
  isPreview: boolean
  posters: {
    data: TopPosterModel[]
  }
  appConfig: AppInfoModel
  faqs: PageModel
}

const IndexPage: NextPage<Props> = (props) => {
  const pageAttributes = props.page.data.attributes
  const customerReviews = loadash.get(pageAttributes, 'hero[0].customerReviews')
  const infoBlocks = loadash.get(pageAttributes, 'hero[0].infoBlocks')
  return (
    <Fragment>
      <Head>
        <link rel="canonical" href={Env.SiteUrl} />
      </Head>
      <PageSeo title={pageAttributes.title} seo={pageAttributes.seo} />
      {props.isPreview ? <PreviewNotice /> : null}

      <HeaderContainer posters={props.posters} menu={props.menu} />
      <HomeHeroSection data={pageAttributes.hero[0] as HomeHeroModel} />
      <CarouselSlider reviews={customerReviews}></CarouselSlider>
      <InfoBlocks infoBlocks={infoBlocks} />
      <AppStoreSection data={pageAttributes?.hero[0] as HomeHeroModel} />

      {pageAttributes.body.map((comp, idx) => (
        <Renderer key={idx} data={comp} faqs={props.faqs} />
      ))}

      <Footer data={props.footer} />
    </Fragment>
  )
}

type RendererProps = {
  data: any
  faqs: PageModel
}
type AvailableComponents = { [key: string]: ReactElement }
const Renderer: FunctionComponent<RendererProps> = (props) => {
  const faqsData = props.faqs.data.attributes

  const componentEntries: AvailableComponents = {
    'individual-section': <IndividualSection iconsData={props.data} />,
    'service-section': <ServiceSection contentBlock={props.data} />,
    'zigzag-text-with-image': <TextWithImage contentBlock={props.data} />,
    'medium-orchid-cta': <MediumOrchidCta data={props.data} />,
    'faq-cta': (
      <Faq faqs={faqsData.body[0] as FaqCollectionModel} ctaData={props.data} />
    ),
  }

  return (
    <Fragment>
      {props.data.elementSlug && componentEntries[props.data.elementSlug]}
    </Fragment>
  )
}

export const getStaticProps: GetStaticProps = async (context) => {
  const isPreview = context.preview
  const allPromise = ApiPromise.Page.GetHomePageWithHeaderFooter(isPreview)
  const [menuPromise, pagePromise, footerPromise, postersPromise, faqPromise] =
    await Promise.all(allPromise)
  const [menu, page, footer, posters, faqs] =
    Converters.KeysConvert.ConvertAllToLower([
      menuPromise.data,
      pagePromise.data,
      footerPromise.data,
      postersPromise.data,
      faqPromise.data,
    ])
  const appConfig = await ApiPromise.Layout.AppConfig()
  return {
    props: {
      page,
      menu,
      footer,
      posters,
      faqs,
      appConfig,
      isPreview: isPreview ? true : false,
    },
    revalidate: Env.PropsRevalidateTime,
  }
}

export default IndexPage
