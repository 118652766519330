import ResponsiveImage from 'components/core/ResponsiveImage'
import Markdown from 'markdown-to-jsx'
import { GridTextBlock } from 'types/generatedTypes'

export default function InfoBlocks({
  infoBlocks,
}: {
  infoBlocks: GridTextBlock[] | undefined
}) {
  return (
    <section className="pb-10 md:pb-20 xl:pb-28">
      <div className="container !max-w-[1300px] font-Manrope font-normal">
        {infoBlocks?.map((info) => (
          <div
            key={info?.id}
            className="liner-gradient border py-12 px-5 md:p-14 xl:p-[80px] rounded-[35px] shadow-xl relative"
          >
            <div className="flex flex-wrap-reverse md:flex-nowrap gap-4 items-center">
              <div className="w-full md:w-1/2">
                <h2 className="font-Manrope md:text-4xl md:leading-[46px] font-bold text-[#001064] mb-5 md:max-w-[428px]">
                  {info?.title}
                </h2>
                <div className="text-xl font-normal text-[#001064]">
                  <Markdown>{info?.content ?? ''}</Markdown>
                </div>
              </div>
              <div className="w-full md:w-1/2 xl:px-24">
                <ResponsiveImage data={info?.image} />
              </div>
            </div>
          </div>
        ))}
      </div>
    </section>
  )
}
